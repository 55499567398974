import { ComponentProps, JSX } from "solid-js";
import c from "class-c";
import { PropsFor } from "solid-u";

import Color from "@repo/utils/Color";
import UObject from "@repo/utils/UObject";
import createPropsProvider from "@repo/utils-solid/createPropsProvider";

import ContainerButton from "../buttons/ContainerButton";
import ListTile from "../info/ListTile";
import Link from "../navigation/Link";

import styles from "./Card.module.scss";

declare namespace Card {
  type Props = UObject.Override<
    ComponentProps<"div">,
    {
      class?: string;
      raised?: boolean;
      dugout?: boolean;
      outline?: Color | boolean;
      style?: JSX.CSSProperties;
      children: JSX.Element;
      marginCollapsible?: boolean;
    }
  >;
}

function Card(_props: Card.Props) {
  const {
    children,
    dugout,
    raised,
    outline,
    class: className,
    style,
    marginCollapsible,
    ...props
  } = Card.PropsProvider.useMerge(_props) satisfies D;

  return (
    <div
      {...props}
      class={c`
        ${c(styles)`
          card
          ${{ dugout, raised, marginCollapsible }}
        `}
        ${className}
      `}
      style={{
        ...style,
        "box-shadow": dugout
          ? ""
          : (outline as any) &&
            `inset 0 0 0 1px ${outline === true ? "var(--outline)" : outline}`,
      }}
    >
      {marginCollapsible && <div class={styles["top-offset"]} />}
      {children}
      {marginCollapsible && <div class={styles["bottom-offset"]} />}
    </div>
  );
}

declare namespace Card.Item {
  type Props = Omit<ListTile.Props, "trailing"> & {
    href?: string;
    target?: "_blank";
    onClick?(e: MouseEvent): void;
  };
}

Card.Item = ({
  propsFor,
  class: className,
  href,
  target,
  onClick,
  ...props
}: D<Card.Item.Props>) => {
  return (
    <Link.If when={href} href={href!} target={target}>
      <ContainerButton.If when={onClick && !href} onClick={onClick}>
        <ListTile
          align="center"
          class={c`${styles["card-item"]} ${className}`}
          propsFor={PropsFor.merge(
            {
              leading: { class: "icon:medium" },
              title: { class: "text:large+" },
              trailing: { class: "icon:medium" },
            },
            propsFor,
          )}
          trailing={(href || onClick) && ""}
          {...props}
        />
      </ContainerButton.If>
    </Link.If>
  );
};

declare namespace Card.Label {
  type Props = Card.Item.Props;
}
Card.Label = ({ propsFor, ...props }: D<Card.Label.Props>) => {
  return (
    <Card.Item
      propsFor={PropsFor.merge(
        { title: { class: "text:heading-4++ color:primary" } },
        propsFor,
      )}
      {...props}
    />
  );
};

Card.PropsProvider = createPropsProvider<Card.Props>("Card");

export default Card;
