import { Transaction as CanadaTransactionPb } from "@repo/protobuf/gen/kikoff_canada/protobuf/models/transaction_pb";
import Protobuf from "@repo/protobuf/utils";
import Case from "@repo/utils/Case";
import Enum from "@repo/utils/Enum";

type Transaction = {
  id: Transaction.Id;
  parentId: Transaction.Id;

  operation?: Transaction.Operation;

  creditable: Transaction.Entity;
  debitable: Transaction.Entity;

  amountCents: number;
  transactedAt: Date;
};

namespace Transaction {
  export type Id = string & {};

  export type Operation =
    | "refund"
    | "payment"
    | "subscriptionActivation"
    | "storePurchase";
  export namespace Operation {
    const stringMap: Record<Operation, string> = {
      refund: "Refund",
      subscriptionActivation: "Subscription activation",
      payment: "Payment",
      storePurchase: "Store purchase",
    };
    export const format = (operation: Operation | undefined) =>
      operation ? stringMap[operation] : "None";
  }

  export type Entity = {
    type: Entity.Type;
    id: string;
  };
  export namespace Entity {
    export type Type =
      | "paymentMethod"
      | "subscriptionInvoice"
      | "storeOrder"
      | "payoffFromKikoff";
  }
}

export default Transaction;

export namespace TransactionCanada {
  export const normalize = (proto: CanadaTransactionPb): Transaction => ({
    id: proto.id,
    parentId: proto.parentId,
    amountCents: proto.amountCents,
    creditable: Entity.normalize(proto.creditable!),
    debitable: Entity.normalize(proto.debitable!),
    transactedAt: Protobuf.Timestamp.toDate(proto.transactedAt!),
    operation: Operation.normalize(proto.operation),
  });

  export namespace Entity {
    export const normalize = (
      proto: CanadaTransactionPb.Transactable,
    ): Transaction.Entity => {
      const type = Case.fromConstant(
        Enum.keyOf(CanadaTransactionPb.Transactable.Type, proto.type),
      ).toCamel();
      if (type === "unknown")
        throw new Error("Unexpected UNKNOWN type in transaction entity.");

      return {
        id: proto.id,
        type,
      };
    };
  }
  export namespace Operation {
    export const normalize = (proto: CanadaTransactionPb.Operation) => {
      const operation = Case.fromConstant(
        Enum.keyOf(CanadaTransactionPb.Operation, proto),
      ).toCamel();
      if (operation === "unknown") return;
      return operation;
    };
  }
}
