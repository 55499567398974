import { UpdateAddressRequest } from "@repo/protobuf/gen/web/public/onboarding_pb";
import { MessageFrom, PartialMessage } from "@repo/protobuf/utils";
import Address from "@repo/utils/Address";

export namespace AddressUsa {
  export const normalize = (
    proto: MessageFrom<UpdateAddressRequest>,
  ): Address => ({
    addressLine1: proto.addressLine1,
    addressLine2: proto.addressLine2,
    locality: proto.city,
    subdivision: proto.state,
    postalCode: proto.zip,
    country: "US",
  });
  export const denormalize = (
    address: Address,
  ): PartialMessage<UpdateAddressRequest> => ({
    addressLine1: address.addressLine1,
    addressLine2: address.addressLine2,
    city: address.locality,
    state: address.subdivision,
    zip: address.postalCode,
  });
}

export namespace AddressCanada {
  export const provinceByAbbreviation = {
    AB: "Alberta",
    BC: "British Columbia",
    MB: "Manitoba",
    NB: "New Brunswick",
    NL: "Newfoundland and Labrador",
    NS: "Nova Scotia",
    NT: "Northwest Territories",
    NU: "Nunavut",
    ON: "Ontario",
    PE: "Prince Edward Island",
    QC: "Quebec",
    SK: "Saskatchewan",
    YT: "Yukon",
  };
}
