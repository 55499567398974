import Chainable from "./Chainable.mts";

namespace Money {
  export const format = Chainable.create(
    Chainable.wrapFactory(
      ({
        alwaysShowCents = false,
        noSign = false,
        withPlus = false,
        defaultStr = "$-",
      }) =>
        (cents: number | undefined | null) => {
          if (cents == null || Number.isNaN(cents)) return defaultStr;

          const str = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            ...(cents % 100 === 0 &&
              !alwaysShowCents && {
                minimumFractionDigits: 0,
              }),
          })
            .format(cents / 100)
            .slice(noSign ? 1 : 0);

          return withPlus && cents > 0 ? `+${str}` : str;
        },
    ),
    {
      cents: { alwaysShowCents: true },
      noSign: { noSign: true },
      withPlus: { withPlus: true },
      default: (str: string) => ({ defaultStr: str }),
    },
  );
}

export default Money;
