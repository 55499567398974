import Css from "@repo/utils/Css";

namespace UHTMLElement {
  export function parents(element: HTMLElement) {
    const parents: HTMLElement[] = [];

    (function getParents(el: HTMLElement | null) {
      if (!el) return;

      parents.push(el);
      getParents(el.parentElement);
    })(element);

    return parents;
  }

  export function scrollParents(element: HTMLElement) {
    const parents: HTMLElement[] = [];

    (function getParents(el: HTMLElement | null) {
      if (!el) return;

      if (el.scrollHeight > el.clientHeight) parents.push(el);

      getParents(el.parentElement);
    })(element);

    return parents;
  }
  scrollParents.listen = (element: HTMLElement, handler: () => void) => {
    const parents = UHTMLElement.scrollParents(element);
    for (const parent of parents) {
      parent.addEventListener("scroll", handler);
    }
    return {
      unlisten() {
        for (const parent of parents) {
          parent.removeEventListener("scroll", handler);
        }
      },
    };
  };
  export function computeTotalTransitionMs(element: HTMLElement) {
    const computed = getComputedStyle(element);

    return (
      Css.parseTime(computed.transitionDuration) +
      Css.parseTime(computed.transitionDelay)
    );
  }
}

export default UHTMLElement;
