import Chainable from "./Chainable.mts";

export const msIn = (() => {
  const second = 1000;
  const minute = second * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = month * 12;

  return {
    second,
    minute,
    hour,
    day,
    week,
    month,
    year,
  } as const;
})();

namespace Duration {
  export const format = Chainable.create(
    Chainable.wrapFactory(
      ({
        pluralize = false,
        // Prevent more precise units from showing if this many less precise units are showing
        maxUnits = Infinity,
      }) =>
        (ms: number, formatString: string) => {
          let res = formatString;

          const tokensOrderedByPrecision = {
            Y: msIn.year,
            M: msIn.month,
            W: msIn.week,
            D: msIn.day,
            h: msIn.hour,
            m: msIn.minute,
            s: msIn.second,
          };

          for (const [token, msInToken] of Object.entries(
            tokensOrderedByPrecision,
          )) {
            if (maxUnits === 0) break;

            const i = res.indexOf(`%${token}`);

            if (i === -1) continue;

            const amount = Math.floor(ms / msInToken);
            const nextPrefix = res.slice(i + 1).match(/\?|%/)?.[0];
            if (amount === 0 && nextPrefix === "?") continue;
            --maxUnits;

            ms -= amount * msInToken;
            res = res.replace(`%${token}`, `${amount}`);
            res = `${res.slice(0, i)}${res
              .slice(i)
              .replace(/\?|%/, (s) =>
                (s === "?" ? "" : s).concat(
                  pluralize && amount !== 1 ? "s" : "",
                ),
              )}`;
          }

          return res
            .replace(/%.*?\?/g, "")
            .replace(/ +/g, " ")
            .trim();
        },
    ),
    {
      pluralize: { pluralize: true },
      maxUnits: (maxUnits: number) => ({ maxUnits }),
    },
  );
}

export default Duration;
